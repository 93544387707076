import { HttpClient } from '@/services/http';

export class NotificationService extends HttpClient {
  async getNotifications() {
    const result = await this.get('v1/notifications');
    return result;
  }

  async readNotification(id) {
    const result = await this.post(`v1/notifications/${id}/read`);
    return result;
  }
}
