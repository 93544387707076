<template>
  <div class="b-notification__content">
    <v-container v-if="!items.length">
      <span class="b-notification--empty"> Nenhuma nova notificação </span>
    </v-container>

    <ul class="b-notification__list">
      <li
        v-ripple
        v-for="(item, i) in items"
        :key="i"
        :class="item.url ? 'cursor-pointer' : 'cursor-initial'"
        @click="execute(item)"
      >
        <div :class="[item.read ? 'font-weight-light' : 'font-weight-bold']">
          {{ item.title }}
        </div>

        <div v-if="item.message" class="b-notification__list--message">
          {{ item.message }}
        </div>

        <div class="b-notification__list--date">
          {{ item.friendlyDate }}

          <v-spacer />

          <v-icon x-small :color="notifyColor(item.level)" v-if="!item.read">
            fa-solid fa-circle
          </v-icon>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { NotificationService } from '@/services/api/notification';

export default {
  props: {
    items: {
      type: Array,
      require: true
    }
  },

  methods: {
    async execute(item) {
      if (item.read === false) {
        try {
          const notificationService = new NotificationService();
          const { status } = await notificationService.readNotification(
            item.id
          );

          if (status === 204) {
            this.$store.commit('notification/readNotification', item);
          }
        } catch (error) {
          throw new Error(error);
        }
      }

      if (!item.url) return;

      if (this.btnViewAll === false) {
        this.$emit('close');
      }

      const newUrl = item.url.split('#');

      if (this.$route.path.split('/')[1] === newUrl[1].split('/')[1]) {
        this.$router.replace(newUrl[1], () => {
          this.$router.go(0);
        });

        return;
      }

      this.$router.push(newUrl[1]);
    },

    notifyColor(item) {
      let color = {
        Sucesso: 'success',
        Aviso: 'warning',
        Critico: 'error',
        Info: 'info'
      };

      return color[item];
    }
  }
};
</script>
